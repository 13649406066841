export default {
    computed: {
        deferredPrompt() {
            return this.$store.state.deferredPrompt
        },
        devTools() {
            if(process.env.NODE_ENV === 'dev') {
                return true
            } else {
                return false
            }
        },
        cart() {
            if(this.config?.header_setting?.product_cart)
                return () => import(`./Cart`)
            else
                return null
        },
        returnCart() {
            if(this.config?.order_setting?.purchase_returns)
                return () => import(`./Return`)
            else
                return null
        },
        // pwaBanner() {
        //     if(this.deferredPrompt)
        //         return () => import(`./PWABanner`)
        //     else
        //         return null
        // },
        // devComponent() {
        //     return () => import(`./DevTools`)
        // },
        // 
        // favorites() {
        //     if(this.config?.header_setting.favorite)
        //         return () => import(`./Favorites`)
        //     else
        //         return null
        // },
        notification() {
            if(this.config?.header_setting?.notification)
                return () => import(`./Notification`)
            else
                return null
        },
        // settingNav() {
        //     if(this.config?.header_setting.settingNav)
        //         return () => import(`./SettingNav`)
        //     else
        //         return null
        // },
        // history() {
        //     if(this.config?.header_setting.history)
        //         return () => import(`./History`)
        //     else
        //         return null
        // },
        // i18nSwicth() {
        //     if(this.config?.header_setting.i18n)
        //         return () => import(`../i18n`)
        //     else
        //         return null
        // },
        // widgetsButton() {
        //     if(this.config?.header_setting.widgets)
        //         return () => import(`./Widgets`)
        //     else
        //         return null
        // },
        // searchBlock() {
        //     if(this.config?.header_setting.search)
        //         return () => import(`./SearchBlock`)
        //     else
        //         return null
        // }
    }
}